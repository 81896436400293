//? LINK TASK VALIDAZIONE POST CON FILE PDF
//? https://asters-ai.atlassian.net/issues/AS-567?jql=text%20~%20%22validation%22%20ORDER%20BY%20created%20DESC

//? Link con probabile info sui media per social
//? https://docs.ayrshare.com/additional-info/image-and-video-requirements

//? 25/03/2024 - Aggiornati requisiti VIDEO
//? https://www.notion.so/ecosystem-asters/d1d7b0ecdca14f5bb10ad6f2cc89324a?v=12bc85548fcd4a61971d1d4a3168c319&p=c4609108eda6431bbd9dd57d6f2d2500&pm=s

import { Media } from 'types'

export const socialCheckConfig = {
  // ------------- BASIC FUNCTIONS -------------

  /**
   * @param value - Value to check
   * @param max - max value allowed
   * @returns {boolean} - True if value is under max
   */
  checkValueIsUnder: (value: number, max: number) => {
    return value <= max
  },

  /**
   * @param value - Value to check
   * @param min - min value allowed
   * @returns {boolean} - True if value is over min
   */
  checkValueIsOver: (value: number, min: number) => {
    return value >= min
  },

  /**
   * @param value - Value to check
   * @param target - Value to compare
   * @returns {boolean} - True if value is equal to target
   */
  checkValueIsEqual: (value: number | string, target: number | string) => {
    return value === target
  },

  /**
   * @param medias - Array of media objects
   * @param text - String of text
   * @returns {boolean} - True if both medias and text are empty
   */
  isEmpty: (medias: Media[], text: string, linkRef?: string) => {
    if (linkRef) return medias.length === 0 && text.length === 0 && linkRef.length === 0
    return medias.length === 0 && text.length === 0
  },

  /**
   * @param medias - Array of media objects
   * @returns {boolean} - True if media array contains video and images
   */
  containsPhotoAndVideo: (medias: Media[]) => {
    if (medias.length < 2) return false

    const hasImage = medias.some((media) => media.metadata.fileType === 'image')
    const hasVideo = medias.some((media) => media.metadata.fileType === 'video')

    return hasImage && hasVideo
  },

  /**
   * @param text Testo del post
   * @param maxChars Massimo numero di caratteri accettati dal social
   * @returns true se il testo è minore o uguale al massimo numero di caratteri
   */
  checkTextLength: (text: string, maxChars: number) => {
    return text.length <= maxChars
  },

  /**
   * @param mediaExtension - Extension of the media
   * @param validExtensions - Array of valid extensions
   * @returns {boolean} - True if media extension is valid
   */
  checkValidExtension: (mediaExtension: string, validExtensions: string[]) => {
    return validExtensions.includes(mediaExtension.toLocaleLowerCase())
  },

  /**
   * @param mediaSize - Size of the media
   * @param maxSize - Max size allowed
   * @returns {boolean} - True if media size is less than max size
   */
  checkSize: (mediaSize: number, maxSize: number) => {
    return mediaSize <= maxSize
  },

  /**
   * @param dimension - Dimension (width or height) of the media
   * @param min - Min Dimension (width or height) allowed
   * @param max - Max Dimension (width or height) allowed
   * @returns {boolean} - True if media is between min and max Dimension (width or height)
   */
  checkDimension: (dimension: number, min: number, max: number) => {
    return dimension >= min && dimension <= max
  },

  /**
   * @param dimension - Dimension (width or height) of the media
   * @param max - Max Dimension (width or height) allowed
   * @returns {boolean} - True if media is under the max Dimension (width or height)
   */
  checkMaxDimension: (dimension: number, max: number) => {
    return dimension <= max
  },

  /**
   * @param mediaWidth - Media width of the media
   * @param mediaHeight - Media height of the media
   * @param minRatio - Min AspectRatio allowed
   * @param maxRatio - Max AspectRatio allowed
   * @returns {boolean} - True if media is between min and max AspectRatio
   */
  checkRatioBetween(mediaWidth: number, mediaHeight: number, minRatio: number, maxRatio: number) {
    const parseRatio = (w, h) => {
      return Number((w / h).toFixed(3))
    }

    const target = parseRatio(mediaWidth, mediaHeight)

    return target >= minRatio && target <= maxRatio
  },

  /**
   * @param aspectRatio - AspectRatio of the media
   * @param acceptedRatios - Array of accepted AspectRatios
   * @returns {boolean} - True if media AspectRatio is in the accepted AspectRatios
   */
  checkRatioAccepted: (aspectRatio: string, acceptedRatios: string[]) => {
    return acceptedRatios.includes(aspectRatio)
  },

  /**
   * @param fps - Frame per second of the video
   * @param min - Min fps allowed
   * @param max - Max fps allowed
   * @returns {boolean} - True if fps is between min and max fps
   */
  checkFps: (fps: number, min: number, max: number) => {
    return fps >= min && fps <= max
  },

  /**
   * @param duration - Duration of the video
   * @param min - Min duration allowed
   * @param max - Max duration allowed
   * @returns {boolean} - True if duration is between min and max duration
   */
  checkDuration: (duration: number, min: number, max: number) => {
    return duration >= min && duration <= max
  },

  /**
   * @param duration - Duration of the video
   * @param max - Max duration allowed
   * @returns {boolean} - True if duration is under the max duration
   */
  checkMaxDuration: (duration: number, max: number) => {
    return duration <= max
  },

  /**
   * @param bitrate - Bitrate of the video
   * @param min - Min bitrate allowed
   * @param max - Max bitrate allowed
   * @returns {boolean} - True if bitrate is between min and max bitrate
   */
  checkBitrate: (bitrate: number, min: number, max: number) => {
    return bitrate >= min && bitrate <= max
  },

  /**
   * Controlla che il primo media sia del tipo specificato
   * @param medias array of media objects
   * @param type  type of media (es: "image" o "video")
   * @returns true if the first media is of the specified type
   */
  checkMediasType: (medias: Media[], type: string) => {
    if (medias.length === 0) return true

    return medias[0].metadata.fileType === type
  },

  // ------------- CAROUSEL FUNCTIONS -------------
  /**
   * @param mediasNum - Number of medias in the carousel
   * @param max - Max number of medias allowed
   * @returns {boolean} - True if number of medias is equal or under the  max value
   */
  checkCarouselDimension: (medias: Media[], max: number) => {
    return medias.length <= max
  },

  /**
   * @param medias - Array of media objects
   * @returns {boolean} - True if all medias have the same aspect ratio
   */
  checkCarouselRatio: (medias: Media[]) => {
    const firstMediaRatio =
      medias[0].metadata?.aspectRatio?.toLocaleLowerCase() != 'n/a' ? medias[0].metadata?.aspectRatio : undefined
    // const firstMediaRatio = medias[0].metadata?.aspectRatio ?? {}
    return medias.every((media) => media.metadata?.aspectRatio === firstMediaRatio)
  },

  /**
   *
   * @param medias array of medias
   * @param type type of media (es: "image" o "video")
   * @param max  max number of media allowed of that type
   * @returns true if the number of media of that type is less or equal to the max
   */
  checkCarouselTypeDimension: (medias: Media[], type: string, max: number) => {
    const filteredMedias = medias.filter((media) => media.metadata.fileType === type)
    return filteredMedias.length <= max
  },

  /**
   * @param medias array of media objects
   * @returns true if the medias array contains more than 1 media
   */
  checkIfIsCarousel: (medias: Media[]) => {
    return medias.length > 1
  },

  //? Last update: 23/05/2024
  instagram: {
    //Limiti generali (applicate e ufficiali)
    maxChars: 2200,
    needMedia: true,
    needText: false,
    maxMediaStories: 1,
    maxMedia: 10, // Tutti devono avere lo stesso ratio

    //Limiti generali (non applicate e non ufficiali)
    maxPostHashtag: 30, // Si possono taggare solo utenti con profili pubblici
    maxStoryHashtag: 10, // Si possono taggare solo utenti con profili pubblici
    maxPostForDay: 50,

    // IMMAGINI (applicate e ufficiali)
    imgTypes: ['jpeg', 'jpg', 'png', 'webp', 'gif'],
    maxImgSize: 10000000, // 10MB
    minImgRatio: 0.562,
    maxImgRatio: 1.91,
    minVisibleImgRatio: '9:16',
    maxVisibleImgRatio: '1,91:1',

    // IMMAGINI THUMBNAIL REEL (applicate e ufficiali)
    reelThumbImgTypes: ['jpeg', 'jpg', 'png', 'webp'],
    maxReelThumbImgSize: 8000000, // 8MB

    // VIDEO (applicate e ufficiali)
    videoTypes: ['mp4', 'mov'],
    codecVideo: ['h264'],
    maxVideoBitrate: 5000000, // 5MB
    maxVideoSize: 8000000, // 100MB
    maxVideoWidth: 1920,
    minVideoFps: 23,
    maxVideoFps: 60,
    minVideoDuration: 3,
    maxVideoDuration: 90,

    // STORIES (applicate e ufficiali)
    storiesImgTypes: ['jpeg', 'jpg', 'png'],
    storiesMaxImageSize: 20000000, // 20MB
    storiesVideoTypes: ['mp4', 'mov'],
    maxStoriesDuration: 150, // 2minuti 30secondi
    maxStoriesVideoSize: 650000000, // 650MB
  },

  //? Last update: 23/05/2024
  facebook: {
    //Limiti generali (applicate e ufficiali)
    maxChars: 4500,
    canContainPhotoAndVideo: false,
    needTextOrMedia: true,
    maxMedia: 100, // SOLO IMG

    //Limiti generali (non applicate e non ufficiali)
    maxPostForDay: 50,

    // IMMAGINI (applicate e ufficiali)
    imgTypes: ['jpeg', 'jpg', 'png', 'gif', 'webp'],
    maxImgSize: 10000000, // 10MB

    // VIDEO (applicate e ufficiali)
    videoTypes: [
      '3g2',
      '3gp',
      '3gpp',
      'asf',
      'avi',
      'dat',
      'divx',
      'dv',
      'f4v',
      'flv',
      'm2ts',
      'm4v',
      'mkv',
      'mod',
      'mov',
      'mp4',
      'mpe',
      'mpeg',
      'mpeg4',
      'mpg',
      'mts',
      'nsv',
      'ogm',
      'ogv',
      'qt',
      'tod',
      'ts',
      'vob',
      'wmv',
    ],
    minVideoFps: 20,
    minVideoDuration: 3,
    maxVideoDuration: 90,
    minVideoWidth: 540,
    acceptedVideoRatios: ['16:9', '9:16', '1:1', '4:5', '4:3', '5:3', '3:5', '1.91:1'],
  },

  //? Last update: 23/05/2024
  linkedin: {
    //Limiti generali (applicate e ufficiali)
    canContainPhotoAndVideo: false,
    needTextOrMedia: true,
    maxChars: 3000,
    maxMedia: 20, // Solo per foto , per video massimo 1

    //Limiti generali (non applicate e non ufficiali)
    maxPostForDay: 10,
    maxPostForMonth: 100,

    // IMMAGINI (applicate e ufficiali)
    imgTypes: ['jpeg', 'jpg', 'png', 'gif', 'webp'],
    maxImgSize: 5000000, // 5MB

    // VIDEO (applicate e ufficiali)
    maxVideoWidth: 4096,
    maxVideoHeight: 2304,
    minVideoDuration: 3,
    maxVideoDuration: 600, // 10minuti
    maxVideoBitrate: 30000000, // 30MB
    minVideoFps: 10,
    maxVideoFps: 60,
    maxVideoSize: 200000000, // 500MB
    allowedVideoExtensions: ['mp4'],
  },

  //? Last update: 23/05/2024
  x: {
    //Limiti generali (applicate e ufficiali)
    needTextOrMedia: true,
    maxChars: 280,
    maxMedia: 4,

    //Limiti generali (non applicate e non ufficiali)
    maxPostForDay: 2400,

    // IMMAGINI (applicate e ufficiali)
    imgTypes: ['jpeg', 'jpg', 'png', 'gif', 'webp'],
    maxImgSize: 5000000, // 5MB

    // GIF (non applicate e non ufficiali)
    maxGifSize: 15000000,
    maxGifFrame: 350,
    maxGifPixels: 300000000,

    // VIDEO (applicate e ufficiali)
    codecVideo: ['h264'],
    codecAudio: ['aac'],
    minVideoWidth: 32,
    maxVideoWidth: 1280,
    minVideoHeight: 32,
    maxVideoHeight: 1024,
    maxVideoSize: 1000000000, // 1GB
    minVideoFps: 30,
    maxVideoFps: 60,
    minVideoDuration: 0.5,
    maxVideoDuration: 140,
    minVideoRatio: 0.333,
    maxVideoRatio: 3,
    visibleVideoRatioMin: '1:3',
    visibleVideoRatioMax: '3:1',
  },

  //? Last update: 23/05/2024
  youtube: {
    //Limiti generali (applicate e ufficiali)
    needMedia: true,
    onlyVideo: true,
    needText: true,
    maxTitleChars: 100,
    maxCharsDescription: 5000,
    maxMedia: 1,

    //Limiti generali (non applicate e non ufficiali)
    maxPostForDay: 100,

    // THUMBNAIL (applicate e ufficiali)
    maxThumbnailSize: 2000000, // 2MB

    // VIDEO (applicate e ufficiali)
    videoTypes: ['mp4', 'mov'],
    maxVideoSize: 3000000000, // 3GB (presa da competitor Sprout)
    //? Attualmente non abbiamo modo di controllare se l'utente è verificato o meno, quindi usiamo il valore di maxDurationVerified
    maxDurationUnverified: 900, // 15 minuti
    maxDurationVerified: 43200, // 12 ore
  },

  //? Last update: 25/03/2024
  tiktok: {
    // Limiti generali (applicate e ufficiali)
    needMedia: true,
    needText: false,
    onlyVideo: true,
    maxChars: 280,
    maxMedia: 1,

    // Limiti generali (non applicate e non ufficiali)
    maxPostForDay: 2400,

    // Thumbnail (non applicate e non ufficiali)
    thumbnailTypes: ['jpeg', 'jpg', 'png'],
    maxThumbnailSize: 2000000,
    thumbnailRatios: ['1:1', '16:9'],

    // Video (applicate e ufficiali)
    minVideoDuration: 3,
    maxVideoDuration: 600, // 10 minuti
    minVideoFps: 23,
    maxVideoFps: 60,
  },

  //TODO: Da ricontrollare quando si implementerà pinterest
  pinterest: {
    maxPostForDay: 1000,
    bustinessMaxPostForDay: 5000,
    maxTitleChars: 100,
    maxChars: 500,
    needMedia: true,
    maxMedia: 10,
    mediaTypes: ['jpeg', 'png', 'tiff', 'bmp', 'webp', 'mp4', 'm4v', 'mov'],

    isTypeWeb: (media: any) => media.mimetype.includes('web'),
    typeWebMaxSize: 20000000,
    minMediaRatio: '1/1',
    maxMediaRatio: '16/9',
    checkMediaRatio: (width: number, height: number) => {
      const aspectRatio = width / height
      return aspectRatio >= 1 && aspectRatio <= 16.9
    },

    // Img
    maxImgSize: 5000000,

    // carousel
    carouselDimension: (mediasNum: number) => mediasNum >= 2 && mediasNum <= 5,
    carouselMediaSize: (medias: any[]) => {
      let checkMediaSize = true

      medias.forEach((media) => {
        if (media.mimetype.includes('image') && !media.mimetype.includes('web') && media.size <= 5000000) {
          return (checkMediaSize = false)
        }

        if (media.mimetype.includes('image') && media.mimetype.includes('web') && media.size <= 20000000) {
          return (checkMediaSize = false)
        }

        if (media.mimetype.includes('video') && media.size <= 1000000000) {
          return (checkMediaSize = false)
        }
      })

      return checkMediaSize
    },

    // video
    maxVideoSize: 1000000000,
    codecVideo: ['h264', 'hevc'],
    minVideoFps: 24,
    maxVideoFps: 30,
    videoFps: (fps: number) => fps <= 30,
    minVideoDuration: 4,
    maxVideoDuration: 300,
    videoDuration: (duration: number) => duration >= 4 && duration <= 300,
  },
}
